import React, { useState, useEffect, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { getCheckoutId } from 'helpers/cookies';
import { StorefrontContext } from 'providers/storefront';
import Image from 'next/image';
import CountrySelector from 'components/CountrySelector';
import Logo from 'components/Logo';
import styles from './styles';
import CartCounter from './CartCounter';
import Primary from './Primary';
import Countdown from 'react-countdown';
import { useRouter } from 'next/router';
import AccountPrimary from './AccountPrimary';
import RegionSwitchButton from 'components/NavigationButtonIcons/RegionSwitchButton';
import DrawerButtons from 'components/NavigationButtonIcons/DrawerButtons';
import { fGetPlural } from 'utils/functions';
import BannerCountdownTimer from '../BannerCountdownTimer';
import Prismic from '../../../../helpers/Prismic';
import { ThreeDots } from 'react-loader-spinner';

const Navigation = ({
  header,
  opacityRef,
  rtsProducts,
  customProducts,
  workstationProducts,
  customLaptops,
}) => {
  const router = useRouter();

  const [checkoutId, setCheckoutId] = useState(getCheckoutId());
  const { setIsCartOpen, checkout, isLoggedIn, setIsLoggedIn } =
    useContext(StorefrontContext);
  const { setIsAnnouncementBarOpen, isAnnouncementBarOpen } =
    useContext(StorefrontContext);
  const [menuItems, setMenuItems] = useState([]);
  useEffect(() => {
    if (checkout?.id) setCheckoutId(checkout.id);
  }, [checkout]);
  const [search, setSearch] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(true);
  const { setAllPcRtsProducts, allProducts, setAllProducts } =
    useContext(StorefrontContext);

  const getMegaMenuColumns = (megaMenuItems) => {
    if (!megaMenuItems) return [];
    const columns = [];
    let currentSecondLevelIndex = 0;
    megaMenuItems.forEach((childMenuItem, index) => {
      const sliceType = childMenuItem.slice_type;
      // eslint-disable-next-line no-plusplus
      if (sliceType === '1st_level' && index !== 0) currentSecondLevelIndex++;
      if (!columns[currentSecondLevelIndex]) {
        columns[currentSecondLevelIndex] = [];
      }

      columns[currentSecondLevelIndex].push(childMenuItem);
    });

    return columns;
  };
  useEffect(() => {
    if (header && header.body) {
      setMenuItems(getMegaMenuColumns(header.body));
    }
  }, [header]);

  const handleChatToggle = () => {
    window.FrontChat('show');
  };

  const handleCartOpen = () => {
    setIsCartOpen(true);
  };

  const [isBannerStateLoaded, setIsBannerStateLoaded] = useState(false);

  const topBanner =
    header &&
    header?.body?.filter((item) => item?.slice_type === 'shipping_banner');

  const topBannerTimer =
    header &&
    header?.body?.filter((item) => item?.slice_type === 'shipping_banner');

  const topBannerContents = topBanner && topBanner[0]?.primary?.banner_contents;

  const hideBannerTimerRunsOut =
    topBanner && topBanner[0]?.primary?.hide_when_timer_runs_out;

  const topBannerTimerContents =
    topBannerTimer && topBannerTimer[0]?.primary?.countdown_end;

  const Completionist = () => <span></span>;
  const renderer = ({
    days = null,
    hours = null,
    minutes = null,
    seconds = null,
    completed = null,
  }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span>
          {' '}
          | Ends in {days}d {hours}h {minutes}m {seconds}s
        </span>
      );
    }
  };

  function filterUniqueByTitle(products) {
    const uniqueProductsMap = new Map();
    for (const product of products) {
      if (!uniqueProductsMap.has(product.title1)) {
        uniqueProductsMap.set(product.title1, product);
      }
    }
    return Array.from(uniqueProductsMap.values());
  }

  async function getPcModelsByNames(names) {
    try {
      const response = await fetch(
        `/api/search/getAllPcModelsByNames?names=${names.join(',')}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const results = await response.json();
      return results;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  }

  function getAllElementsInSearch() {
    let finalCustomProducts = [];
    let slpPcModels = [];

    // GET ALL CUSTOM PRODUCTS
    customProducts?.body?.map((data, i) => {
      data.items.map((item, i) => {
        finalCustomProducts = [...finalCustomProducts, { product: item }];
      });
    });

    customLaptops?.body?.map((data, i) => {
      data.items.map((item, i) => {
        item.tags = 'laptops';
        finalCustomProducts = [...finalCustomProducts, { product: item }];
      });
    });

    finalCustomProducts?.map((data, i) => {
      const slp = data.product.link.split('/');
      slpPcModels = [...slpPcModels, slp[2]];
    });

    const newSlpModels = slpPcModels.map((model) => {
      if (model.includes('-')) {
        return model.split('-')[0];
      }
      return model;
    });

    function filterDuplicates(arr) {
      return arr.filter((item, index) => arr.indexOf(item) === index);
    }

    const updatedSlpModels = filterDuplicates(newSlpModels);

    const handleFlatArray = (arr) => {
      let filteredItems = arr.map((bodyItem) => {
        return bodyItem.items;
      });
      let filtered = filteredItems.map((el) => {
        return el.filter(
          (itm) => itm.product !== null && typeof itm.product !== 'undefined',
        );
      });

      const flatProducts = filtered.flat().filter((el) => el.product !== null);

      return flatProducts;
    };

    updatedSlpModels.push('*-milk');
    const sortedSlpModels = updatedSlpModels.sort();
    const promises = [];
    const chunkSize = 3;

    for (let i = 0; i < sortedSlpModels.length; i += chunkSize) {
      const chunk = sortedSlpModels.slice(i, i + chunkSize);
      promises.push(getPcModelsByNames(chunk));
    }

    Promise.all(promises)
      .then((response) => {
        const allPcModels = response.flat();
        const filteredAllPcModels = allPcModels.filter(
          (pcModel) => pcModel.price < 90000,
        );

        let filteredPcModels = [];

        filteredAllPcModels.map((option) => {
          const graphicsCardOptions = option?.modelOptionGroup?.find(
            (options) =>
              options.optionGroup.option_group_title === 'Graphics Card',
          );

          const cpuOptions = option?.modelOptionGroup?.find(
            (options) => options.optionGroup.option_group_title === 'CPU',
          );

          filteredPcModels = [
            ...filteredPcModels,
            {
              slug: option.slug,
              model_title: option.model_title,
              seo_description: option.seo_description,
              seo_title: option.seo_title,
              thumb_image: option.thumb_image,
              modelOptionGroup: [graphicsCardOptions],
              cpuGroup: [cpuOptions],
              buildType: option.buildType.build_type_name,
            },
          ];
        });

        // GET ALL RTS PRODUCTS
        let filteredItems = rtsProducts?.body?.map((bodyItem) => {
          return bodyItem.items;
        });
        let filtered = filteredItems?.map((el) => {
          return el.filter((itm) => itm.product !== null);
        });
        const flatProducts = filtered?.flat();
        const finalProducts = flatProducts?.filter(
          (el) => el.product !== undefined,
        );

        const pageSearch = [
          {
            title1: 'Gaming PC',
            subtitle: '',
            seo_description: `Build your setup with Singapore's #1 Custom PC Brand. Pairing incredible craftsmanship with a comprehensive curation of premium components, our team of artisans perfect every detail to your exact needs! Enjoy our industry-leading 2-year warranty and lifetime customer support today.`,
            thumb_image:
              'https://images.prismic.io/aftershock-singapore/80be4873-9b16-41ee-aaf2-8641d3c5da42_GAMING+CUSTOM.png?auto=compress,format',
            link: '/gaming/desktops',
          },
        ];

        Prismic.getByUID('collections_accessories', `cls-accessories`)
          .then((res) => {
            const allWorkstationProducts = workstationProducts?.body?.flatMap(
              (itemGroup) => itemGroup.items,
            );
            const uniqueWorkstationProducts = filterUniqueByTitle(
              allWorkstationProducts,
            );
            const allAccessoriesProducts = handleFlatArray(res?.data?.body);
            let allProducts = [...finalProducts, ...filteredPcModels];
            allProducts = [...allProducts, ...uniqueWorkstationProducts];
            allProducts = [...allProducts, ...allAccessoriesProducts];
            allProducts = [...allProducts, ...pageSearch];

            const uniqueProducts = Array.from(
              new Set(allProducts.map((product) => JSON.stringify(product))),
            ).map((product) => JSON.parse(product));

            setAllProducts(uniqueProducts);
            setAllPcRtsProducts(uniqueProducts);
            setLoadingSearch(false);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const checkGraphicsCard = (data, search) => {
    let check = false;
    data.filter((item) => {
      const graphicsCardOptions = item?.modelOptionGroup?.find(
        (options) =>
          options?.optionGroup.option_group_title === 'Graphics Card',
      );

      if (graphicsCardOptions) {
        graphicsCardOptions.options.find((option) => {
          option.component.component_title
            .toLowerCase()
            .includes(search.toLowerCase());
          if (
            (check = option.component.component_title
              .toLowerCase()
              .includes(search.toLowerCase()))
          ) {
            return true;
          }
        });
      }
    });
    return check;
  };

  const checkCpu = (data, search) => {
    let check = false;
    data.filter((item) => {
      const cpuOptions = item?.motherboardGroup?.filter(
        (options) => options?.optionGroup?.option_group_title == 'CPU',
      );

      if (cpuOptions != undefined && cpuOptions.length > 0) {
        cpuOptions[0].options?.find((option) => {
          option.component.component_title
            .toLowerCase()
            .includes(search.toLowerCase());
          if (
            (check = option.component.component_title
              .toLowerCase()
              .includes(search.toLowerCase()))
          ) {
            return true;
          }
        });
      }
    });
    return check;
  };

  const checkTags = (data, search) => {
    const filteredData = data.filter((item) => {
      return item.product?.tags
        ?.toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '')
        .includes(search.replace(/[^a-zA-Z0-9]/g, ''));
    });
    if (filteredData.length != 0) {
      return true;
    }
    return false;
  };

  const handleSearch = (value) => {
    if (value.includes('notebook')) {
      setSearch('laptop');
    } else if (
      value
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '')
        .includes('samedaydelivery')
    ) {
      setSearch('samedayshipping');
    } else if (
      value
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '')
        .includes('sff')
    ) {
      setSearch('itx');
    } else {
      setSearch(value);
    }
  };

  const handleCloseBanner = () => {
    setIsAnnouncementBarOpen(false);
    const currentTime = new Date().getTime();
    localStorage.setItem('bannerClosedTime', currentTime.toString());
  };

  useEffect(() => {
    const bannerClosedTime = localStorage.getItem('bannerClosedTime');
    const timerHasEnded = localStorage.getItem('timerEnded');
    const currentTime = new Date().getTime();

    if (topBannerTimerContents) {
      const bannerEndTime = new Date(topBannerTimerContents).getTime();
      const storedTimerEnd = localStorage.getItem('timerEndTime');

      if (!storedTimerEnd || storedTimerEnd !== bannerEndTime.toString()) {
        localStorage.removeItem('timerEnded');
        localStorage.setItem('timerEndTime', bannerEndTime.toString());
        setIsAnnouncementBarOpen(true);
      }
    }

    if (bannerClosedTime) {
      const timeElapsed = currentTime - parseInt(bannerClosedTime);
      const oneHourInMillis = 10 * 60 * 1000;

      if (timeElapsed > oneHourInMillis) {
        localStorage.removeItem('bannerClosedTime');
        setIsAnnouncementBarOpen(true);
      } else {
        setIsAnnouncementBarOpen(false);
      }
    }

    if (timerHasEnded === 'true') {
      setIsAnnouncementBarOpen(false);
    } else if (!bannerClosedTime && timerHasEnded !== 'true') {
      setIsAnnouncementBarOpen(true);
    }

    setIsBannerStateLoaded(true);
  }, [topBannerTimerContents]);

  return (
    <>
      <nav>
        {isAnnouncementBarOpen && isBannerStateLoaded && (
          <div className="shipping-banner">
            <div className="wrapper shipping-banner">
              {topBannerTimerContents ? (
                <>
                  {topBannerContents}
                  <BannerCountdownTimer
                    date={topBannerTimerContents}
                    handleCloseBanner={handleCloseBanner}
                    hideBannerTimerRunsOut={hideBannerTimerRunsOut}
                  />
                  {/* <Countdown
                    renderer={renderer}
                    date={new Date(topBannerTimerContents)}
                  /> */}
                </>
              ) : (
                topBannerContents
              )}
              <div
                className="close_banner"
                onClick={handleCloseBanner}
                aria-hidden="false"
              >
                <Image
                  draggable={false}
                  src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-close.png?v=1714038756"
                  width={16}
                  height={16}
                  alt="close icon"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        )}
      </nav>
      <nav className="header-nav">
        <div className="top_nav">
          <div className="menu_container">
            <div className="wrapper">
              <div className="nav-logo">
                <Logo width={167} height={36} />
              </div>
              {/* {['/me', '/me/sign-up'].includes(router.pathname) ? (
                <AccountPrimary
                  ksps={kspItems}
                  quiz={quizItems}
                  data={menuItems}
                  opacityRef={opacityRef}
                />
              ) : (
                <Primary
                  ksps={kspItems}
                  quiz={quizItems}
                  data={menuItems}
                  opacityRef={opacityRef}
                />
              )} */}
              <Primary data={menuItems} opacityRef={opacityRef} />

              <div className="icon_section">
                {menuItems.length > 0 && (
                  <div
                    className="wide-search-bar-container"
                    style={search != '' ? { width: '200px' } : {}}
                    onClick={() => getAllElementsInSearch()}
                  >
                    <input
                      className="wide-search-bar"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <button className="search-icon">
                      <Image
                        draggable={false}
                        src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-search-gray.png?v=1713800848"
                        alt="icon-search"
                        width={18}
                        height={18}
                        loading="lazy"
                      />
                    </button>

                    {search != '' && (
                      <div className="search-result-con" id="scroll">
                        {loadingSearch ? (
                          <div className="loading-search">
                            <p className="loading-text">Loading</p>
                            <ThreeDots
                              height="25"
                              width="25"
                              radius="9"
                              color="#950610"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={loadingSearch}
                            />
                          </div>
                        ) : (
                          search != '' &&
                          (allProducts.filter((item) =>
                            item.product?.title
                              ?.replace(/[^a-zA-Z0-9]/g, '')
                              .toLowerCase()
                              .includes(
                                search
                                  .replace(/[^a-zA-Z0-9]/g, '')
                                  .toLowerCase(),
                              ),
                          ).length == 0 &&
                          allProducts.filter((item) =>
                            item.model_title
                              ?.replace(/[^a-zA-Z0-9]/g, '')
                              .toLowerCase()
                              .includes(
                                search
                                  .replace(/[^a-zA-Z0-9]/g, '')
                                  .toLowerCase(),
                              ),
                          ).length == 0 &&
                          allProducts.filter((item) =>
                            item.title1
                              ?.replace(/[^a-zA-Z0-9]/g, '')
                              .toLowerCase()
                              .includes(
                                search
                                  .replace(/[^a-zA-Z0-9]/g, '')
                                  .toLowerCase(),
                              ),
                          ).length == 0 &&
                          allProducts.filter((item) =>
                            item.seo_title
                              ?.replace(/[^a-zA-Z0-9]/g, '')
                              .toLowerCase()
                              .includes(
                                search
                                  .replace(/[^a-zA-Z0-9]/g, '')
                                  .toLowerCase(),
                              ),
                          ).length == 0 &&
                          allProducts.filter((item) =>
                            item.subtitle
                              ?.replace(/[^a-zA-Z0-9]/g, '')
                              .toLowerCase()
                              .includes(
                                search
                                  .replace(/[^a-zA-Z0-9]/g, '')
                                  .toLowerCase(),
                              ),
                          ).length == 0 &&
                          !checkGraphicsCard(allProducts, search) &&
                          !checkCpu(allProducts, search) &&
                          !checkTags(allProducts, search) ? (
                            <div>
                              <p>No result found</p>
                            </div>
                          ) : (
                            allProducts.map((item) => {
                              const checkPcModel =
                                item?.modelOptionGroup?.filter(
                                  (options) =>
                                    options?.optionGroup?.option_group_title ==
                                    'Graphics Card',
                                );
                              const checkPcModelCpu = item?.cpuGroup?.filter(
                                (options) =>
                                  options?.optionGroup?.option_group_title ==
                                  'CPU',
                              );

                              if (
                                item.product?.title
                                  ?.replace(/[^a-zA-Z0-9]/g, '')
                                  .toLowerCase()
                                  .includes(
                                    search
                                      .replace(/[^a-zA-Z0-9]/g, '')
                                      .toLowerCase(),
                                  ) ||
                                item.model_title
                                  ?.replace(/[^a-zA-Z0-9]/g, '')
                                  .toLowerCase()
                                  .includes(
                                    search
                                      .replace(/[^a-zA-Z0-9]/g, '')
                                      .toLowerCase(),
                                  ) ||
                                item.title1
                                  ?.replace(/[^a-zA-Z0-9]/g, '')
                                  .toLowerCase()
                                  .includes(
                                    search
                                      .replace(/[^a-zA-Z0-9]/g, '')
                                      .toLowerCase(),
                                  ) ||
                                item.seo_title
                                  ?.replace(/[^a-zA-Z0-9]/g, '')
                                  .toLowerCase()
                                  .includes(
                                    search
                                      .replace(/[^a-zA-Z0-9]/g, '')
                                      .toLowerCase(),
                                  ) ||
                                item.subtitle
                                  ?.replace(/[^a-zA-Z0-9]/g, '')
                                  .toLowerCase()
                                  .includes(
                                    search
                                      .replace(/[^a-zA-Z0-9]/g, '')
                                      .toLowerCase(),
                                  ) ||
                                item.product?.tags
                                  ?.toLowerCase()
                                  .replace(/[^a-zA-Z0-9]/g, '')
                                  .includes(
                                    search.replace(/[^a-zA-Z0-9]/g, ''),
                                  ) ||
                                item.buildType
                                  ?.toLowerCase()
                                  .includes(search.replace(/[^a-zA-Z0-9]/g, ''))
                              ) {
                                const productImage = item.product?.image?.src
                                  ? item.product?.image?.src
                                  : item.thumb_image
                                  ? item.thumb_image
                                  : item.thumbnail?.url;
                                const productTitle = item.product?.title
                                  ? item.product?.title?.split('/')
                                  : item.model_title
                                  ? item.model_title
                                  : item.title1;
                                const productDescription = item.product
                                  ?.body_html
                                  ? item.product?.body_html?.replace(
                                      /<[^>]*>/g,
                                      '',
                                    )
                                  : item.seo_description;
                                return (
                                  <a
                                    href={
                                      item.product?.handle
                                        ? `/products/${item.product?.handle}`
                                        : item.slug
                                        ? `/pc-models/${item.slug}`
                                        : item.link
                                    }
                                    className="search-section"
                                    onClick={() =>
                                      (window.location.href = item.product
                                        ?.handle
                                        ? `/products/${item.product?.handle}`
                                        : `/pc-models/${item.slug}`)
                                    }
                                  >
                                    <div className="search-image">
                                      <img
                                        draggable="false"
                                        width={'100%'}
                                        src={productImage}
                                      />
                                    </div>
                                    <div className="search-description">
                                      <h1 className="product-title">
                                        {item?.product?.title
                                          ? productTitle[0]
                                          : productTitle}
                                      </h1>
                                      <h1 className="product-subtitle">
                                        {item?.product?.title
                                          ? productTitle[1]
                                          : item?.seo_title
                                          ? item?.seo_title
                                          : item?.subtitle?.replace(
                                              /\bquotation\b/g,
                                              '',
                                            )}
                                      </h1>
                                      <div className="product-description">
                                        {productDescription}
                                      </div>
                                    </div>
                                  </a>
                                );
                              } else if (
                                checkPcModel != undefined ||
                                checkPcModelCpu != undefined
                              ) {
                                const check = checkPcModel[0]?.options?.map(
                                  (option) => {
                                    if (
                                      option.component.component_title
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                    ) {
                                      return true;
                                    }
                                  },
                                );
                                const hasTrueValue = check?.includes(true);

                                const checkCpu =
                                  checkPcModelCpu[0]?.options?.map((option) => {
                                    if (
                                      option.component.component_title
                                        .replace(/[^a-zA-Z0-9]/g, '')
                                        .toLowerCase()
                                        .includes(
                                          search
                                            .replace(/[^a-zA-Z0-9]/g, '')
                                            .toLowerCase(),
                                        )
                                    ) {
                                      return true;
                                    }
                                  });
                                const hasTrueValueCpu =
                                  checkCpu?.includes(true);

                                if (hasTrueValue || hasTrueValueCpu) {
                                  const productImage = item.thumb_image;
                                  const productTitle = item.model_title;
                                  const productDescription =
                                    item.seo_description;
                                  return (
                                    <a
                                      href={`/pc-models/${item.slug}`}
                                      className="search-section"
                                      onClick={() =>
                                        (window.location.href = `/pc-models/${item.slug}`)
                                      }
                                    >
                                      <div className="search-image">
                                        <img
                                          draggable="false"
                                          width={'100%'}
                                          src={productImage}
                                        />
                                      </div>
                                      <div className="search-description">
                                        <h1 className="product-title">
                                          {productTitle}
                                        </h1>
                                        <h1 className="product-subtitle">
                                          {item.seo_title}
                                        </h1>
                                        <div className="product-description">
                                          {productDescription}
                                        </div>
                                      </div>
                                    </a>
                                  );
                                }
                              }
                            })
                          ))
                        )}
                      </div>
                    )}
                  </div>
                )}

                <RegionSwitchButton />

                <div>
                  <DrawerButtons
                    isLoggedIn={isLoggedIn}
                    setIsLoggedIn={setIsLoggedIn}
                  />
                </div>

                <div
                  type="button"
                  className="cart cart_container"
                  onClick={handleCartOpen}
                  aria-hidden="false"
                >
                  <Image
                    draggable={false}
                    src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon-cart.svg?v=1713797761"
                    alt="cart icon"
                    className="cart_icon"
                    layout="fixed"
                    width={24}
                    height={24}
                  />
                  <span>
                    {checkoutId && <CartCounter checkoutId={checkoutId} />}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <style jsx>{styles}</style>
    </>
  );
};

Navigation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  header: PropTypes.any,
};

Navigation.defaultProps = {
  header: null,
};

export default memo(Navigation);
