import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import breakPoints from 'helpers/Styles';
import Cart from 'containers/Cart';
import { StorefrontContext } from 'providers/storefront';
import PreviewMode from 'components/PreviewMode';
import Navigation from './Header/Navigation';
import NavigationMobile from './Header/NavigationMobile';
import Footer from './Footer';
import Head from './Head';
import MobileHeader from './MobileHeader';
import { useRouter } from 'next/router';
import { cleanupChatBot, loadChatBot } from 'helpers/chatBotKnowledgeHub';

const Layout = ({ children, global, preview }) => {
  const headerRef = useRef('');
  const {
    header,
    footer,
    rtsProducts,
    customProducts,
    workstationProducts,
    customLaptops,
  } = global || {};
  const { isMenuOpen, setIsMenuOpen } = useContext(StorefrontContext);

  const openMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const router = useRouter();

  const [isKnowledgeHub, setIsKnowledgeHub] = useState(false);

  useEffect(() => {
    if (!router.isReady) return;

    if (router.pathname.includes('knowledge-hub')) {
      setIsKnowledgeHub(true);
    } else {
      setIsKnowledgeHub(false);
    }
  }, [router.isReady, router.pathname]);

  useEffect(() => {
    if (isKnowledgeHub) {
      loadChatBot();
    } else {
      cleanupChatBot();
    }

    router.events.on('routeChangeStart', (url) => {
      if (!url.startsWith('/knowledge-hub')) {
        cleanupChatBot();
      }
    });

    return () => {
      cleanupChatBot();
      router.events.off('routeChangeStart', cleanupChatBot);
    };
  }, [isKnowledgeHub]);

  return (
    <>
      <Head />
      {!isKnowledgeHub && global && (
        <MobileHeader
          header={header}
          openMenu={openMenu}
          closeMenu={closeMenu}
          isChecked={isMenuOpen}
          rtsProducts={rtsProducts}
          customProducts={customProducts}
          customLaptops={customLaptops}
          workstationProducts={workstationProducts}
        />
      )}
      <NavigationMobile
        header={header}
        isOpen={isMenuOpen}
        openMenu={openMenu}
        closeMenu={closeMenu}
      />
      {!isKnowledgeHub && global && (
        <Navigation
          header={header}
          opacityRef={headerRef}
          rtsProducts={rtsProducts}
          customProducts={customProducts}
          customLaptops={customLaptops}
          workstationProducts={workstationProducts}
        />
      )}
      <div ref={headerRef}>
        {children}
        {!isKnowledgeHub && (
          <>
            <Footer footer={footer} />
            <Cart />
          </>
        )}
        <PreviewMode preview={preview} />
      </div>

      <style jsx>
        {`
          @media (max-width: ${breakPoints.medium}) {
            .header-nav {
              display: none !important;
            }
          }
        `}
      </style>
    </>
  );
};

Layout.propTypes = {
  preview: PropTypes.bool,
};

Layout.defaultProps = {
  preview: false,
};

export default Layout;
