import Head from 'next/head';
import gtm from './gtm';
import klaviyo from './klaviyo';
import hotjar from './hotjar';
import chat from './chat';
import facebookPixel from './facebook-pixel';
import { useRouter } from 'next/router';
import { StorefrontContext } from 'providers/storefront';
import { useEffect, useContext } from 'react';

const icons = () => {
  const config = ['16', '32', '64', '128'];
  const getIcon = (size) => {
    switch (size) {
      case "16":
        return 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/FavIcon16.png?v=1672187508';
      case "32":
        return 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/FavIcon32.png?v=1672187508';
      case "64":
        return 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/FavIcon64.png?v=1672187508';
      case "128":
        return 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/FavIcon128.png?v=1672187508';
      default:
        return "";
    }
  }
  return config.map((size) => (
    <link
      rel="icon"
      key={size}
      size={`${size}x${size}`}
      href={getIcon(size)}
    />
  ));
};

const LayoutHead = () => {
  const { toggleChat, showBothChat } = useContext(StorefrontContext);

  const url =
    process.env.NEXT_PUBLIC_STOREFRONT_URL || 'https://aftershockpc.com.au/';
  const title =
    'AFTERSHOCK PC AUSTRALIA - Custom PCs, Notebooks & Workstations';
  const description =
    'Custom gaming computers that deliver unbeatable performance and value. Our passion lies in creating the perfect system for each user. Experience our award winning range of boutique laptops and desktops.';
  const keywords =
    'aftershock, custom gaming computers, custom pcs, custom notebooks, custom workstations';

    const router = useRouter();
    let interval;
    function setFrontChatPosition() {
      const iframe = document.getElementById('front-chat-iframe');
      if (iframe !== null) {
        iframe.style.transition = 'all 0.5s ease-in-out';
        if (iframe.style.bottom.toString() === '0px') {
          iframe.style.scale = showBothChat ? '0.8' : '1';
          iframe.style.bottom = '0px';
          iframe.style.right = '9px';
          return;
        }
        if (
          iframe.style.bottom.toString() === '135px' &&
          router.pathname !== '/pc-models/[uid]' &&
          router.pathname !== '/products/[...handle]'
        ) {
          iframe.style.bottom = '4px';
          return;
        }
        if (
          router.pathname === '/pc-models/[uid]' ||
          router.pathname === '/products/[...handle]'
        ) {
          const windowWidth = window.innerWidth;
          if (windowWidth > 1521) {
            iframe.style.scale = showBothChat ? '0.8' : '1';
            iframe.style.bottom = '4px';
            iframe.style.right = '9px';
          } else if (windowWidth <= 1521 && windowWidth >= 1025) {
            iframe.style.scale = showBothChat ? '0.8' : '1';
            iframe.style.bottom = '80px';
            iframe.style.right = '9px';
          } else {
            iframe.style.scale = showBothChat ? '0.8' : '1';
            iframe.style.bottom = '125px';
            iframe.style.right = '9px';
          }
  
          return;
        }
        iframe.style.scale = '1';
        iframe.style.bottom = '4px';
      }
    }
    function setFrontChatDefaultPosition() {
      const iframe = document.getElementById('front-chat-iframe');
      if (iframe !== null) {
        if (iframe.style.bottom.toString() === '4px') {
          clearInterval(interval);
        }
        iframe.style.transition = 'all 0.5s ease-in-out';
        iframe.style.scale = showBothChat ? '0.8' : '1';
        iframe.style.right = '9px';
        iframe.style.bottom = '4px';
        return;
      }
    }
    useEffect(() => {
      clearInterval(interval);
      if (
        router.pathname !== '/pc-models/[uid]' &&
        router.pathname !== '/products/[...handle]'
      ) {
        clearInterval(interval);
        interval = setInterval(setFrontChatDefaultPosition, 1000);
      } else {
        clearInterval(interval);
        interval = setInterval(setFrontChatPosition, 1000);
      }
      return () => clearInterval(interval);
    }, [router, showBothChat]);
  
    const showChatComponent = () => {
      if (showBothChat != null) {
        if (showBothChat) {
          return chat;
        } else if (toggleChat && toggleChat != null) {
          return chat;
        }
      } else if (router.asPath != "/pages/contactus" && router.asPath != "/pages/support" ) {
        return chat;
      }
    };

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="robots" content="index,follow" />

        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />

        <meta key="og:url" property="og:url" content={url} />
        <meta key="og:title" property="og:title" content={title} />
        {/* <meta
          key="og:description"
          property="og:description"
          content={description}
        /> */}
        <meta name="twitter:site" content={url} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta name="twitter:image" content="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/ogImage.png?v=1713964496" /> */}
        <meta property="og:type" content="website" />
        {/* <meta property="og:image" content="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/ogImage.png?v=1713964496" /> */}

        {icons()}
        {/* {gtm} */}
        {hotjar}
        {facebookPixel}
      </Head>
      {chat}
      {showChatComponent()}
      {klaviyo}
      {/* {viralSwip} */}
      {/* 
        This break design for the slider in the survey
         <br
          className="Apple-interchange-newline"
          style={{ height: 0, width: 0 }}
        /> */}
    </>
  );
};

export default LayoutHead;
