import { memo } from 'react';
import breakPoints from 'helpers/Styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const EmbedVideo = ({ block }) => {
  const { title1, video_link } = block.primary;

  const videoId = video_link.split('ed/')[1]?.split('?')[0];

  return (
    <>
      <div className="embed_video_slice">
        <div className="video_block">
          {title1 && <div className="embed_video_title">{title1}</div>}
          <div className="iframe_container">
            {/* <iframe
              className="iframe_block"
              src={`${video_link}&autoplay=1&mute=1&loop=1&playlist=${videoId}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe> */}
            <iframe
              src="https://player.vimeo.com/video/1006460367?autoplay=1&loop=1&muted=1&background=1"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
              }}
            ></iframe>
          </div>
        </div>

        <style jsx>{`
          .embed_video_slice {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
          }

          .video_block {
            width: 100%;
            max-width: 1440px;
          }

          .embed_video_title {
            color: #e4e4e4;
            font-family: 'big_noodle_titling';
            font-size: 39px;
            letter-spacing: 0;
            line-height: 40px;
            text-align: center;
            margin-bottom: 20px;
          }

          .iframe_container {
            position: relative;
            width: 100%;
            padding-bottom: 56.25%; /* 16:9 aspect ratio */
            height: 0;
          }

          .iframe_block {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
          }

          @media (max-width: ${breakPoints.medium}) {
            .embed_video_title {
              font-size: 30px;
              line-height: 32px;
            }
          }

          @media (max-width: ${breakPoints.small}) {
            .embed_video_title {
              font-size: 24px;
              line-height: 26px;
            }
          }
        `}</style>
      </div>
    </>
  );
};

export default memo(EmbedVideo);
