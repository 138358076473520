import breakPoints from 'helpers/Styles';
import Link from 'next/link';
import Image from 'next/image';

const RangesColumns = ({ block }) => {
  if (!block) return '';

  const { title1, content, title1_colour, subtitle_colour, background_colour } =
    block.primary;
  const title = title1 && title1.length > 0 ? title1[0].text : null;
  const titleColour = title1_colour ? title1_colour : '#949494';
  const subtitleColour = subtitle_colour ? subtitle_colour : '#e4e4e4';
  const backgroundColour = background_colour ? background_colour : '#000000';
  const contentTexts = content && content.length > 0 ? content[0].text : null;

  return (
    <>
      <div className="ranges_columns wrapper">
        <div className="ranges_columns_container">
          {/* <div className="ranges_columns_title">
						<div>
							<p>{title}</p>
							<h1>{contentTexts}</h1>
						</div>
					</div> */}
          <div className="range_container">
            {block.items.map((item, index) => (
              <div className="range_information" key={index}>
                {item.image !== null ? (
                  <Link
                    legacyBehavior
                    href={item.button_link ? item.button_link : '#'}
                  >
                    <a href={item.button_link ? item.button_link : '#'}>
                      {item.image.url && (
                        <Image
                          draggable={false}
                          src={item.image_link.url}
                          alt={
                            item.image_alt_text
                              ? item.image_alt_text
                              : 'button icon'
                          }
                          height={540}
                          width={360}
                          loading="lazy"
                        />
                      )}
                    </a>
                  </Link>
                ) : null}

                <div className="range_content">
                  {item.title1.length > 0 ? (
                    <h1>{item.title1[0].text}</h1>
                  ) : null}
                  {item.content.length > 0 ? (
                    <p>{item.content[0].text}</p>
                  ) : null}
                </div>

                <div className="link_button">
                  <Link
                    legacyBehavior
                    href={item.button_link ? item.button_link : '#'}
                  >
                    <a
                      href={item.button_link ? item.button_link : '#'}
                      className="button_container"
                    >
                      <button type="button" className="columns_button">
                        {item.button_text || ''}
                      </button>
                      <div className="arrow_right">
                        {/* {arrow && <Image draggable={false}  src={arrow} alt="arrow right icon" />} */}
                        <img
                          draggable={false}
                          width={24}
                          height={24}
                          src="https://cdn.shopify.com/s/files/1/0522/3320/7988/files/icon_arrow.png?v=1713803419"
                          alt="arrow right icon"
                        />
                      </div>
                    </a>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .ranges_columns {
            background: ${backgroundColour};
            color: white;
            width: 100%;
            display: flex;
            justify-content: center;
            padding-top: 30px;
            padding-bottom: 60px;
          }
          .ranges_columns_container {
            width: 100%;
          }
          .range_container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            width: 100%;
            overflow: hidden;
          }
          .range_information {
            width: 25%;
          }
          .ranges_columns_title {
            width: 100%;
            display: flex;
            justify-content: center;
            text-algin: center;
          }
          .ranges_columns_title h1 {
            color: ${subtitleColour};
            font-family: big_noodle_titling;
            font-size: 39px;
            letter-spacing: 0;
            line-height: 48px;
            text-algin: center;
            margin-bottom: 80px;
          }
          .ranges_columns_title p {
            color: ${titleColour};
            margin: 0 auto;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
          }
          .range_information {
            position: relative;
          }
          .range_content {
            position: absolute;
            top: -10px;
            left: 50px;
          }
          .range_content h1 {
            color: #e4e4e4;
            font-family: 'Eurostile-Bold';
            font-size: 28px;
            letter-spacing: 0;
            line-height: 40px;
            margin-bottom: 20px;
          }
          .range_content p {
            width: 90%;
            color: #e4e4e4;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
          }

          .link_button {
            width: 100%;
            position: absolute;
            bottom: 30px;
          }
          .button_container {
            display: flex;
            justify-content: center;
            width: 90%;
            margin: 0 auto;
          }

          .columns_button {
            height: 48px;
            min-width: 210px;
            color: white;
            background: #950810;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            border-radius: 3px 0 0 3px;
            border: none;
            outline: 0;
            padding: 12px 24px;
          }
          .arrow_right {
            width: 48px;
            height: 48px;
            background: #242424;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 0px 3px 03px 0px;
          }
          .button_container:hover > .columns_button {
            background: #800006;
          }
          .button_container:hover > .arrow_right {
            background: #141414;
          }
          .arrow_right img {
            width: 25px;
          }

          @media (max-width: 1024px) {
            .range_content {
              width: 100%;
              text-align: center;
              left: 0;
            }
            .columns_button {
              min-width: 170px;
              font-size: 14px;
              padding: 12px;
            }
          }

          @media (max-width: ${breakPoints.medium}) {
            .columns_button {
              min-width: 210px;
              font-size: 16px;
              padding: 12px 24px;
            }
            .ranges_columns {
              padding: 30px 0;
            }
            .range_container {
              display: flex;
              flex-wrap: wrap;
              margin-top: -40px;
            }
            .range_information {
              width: 50% !important;
            }
            .range_content {
              left: 0;
              padding-left: 15px;
              padding-top: 50px;
            }

            .two_column_button {
              display: block;
              text-transform: uppercase;
            }
            .ranges_columns_title h1 {
              font-size: 26px;
              width: 100% !important;
              margin-bottom: 32px;
            }
            .range_content p {
              width: 90%;
            }
          }
          @media (max-width: 430px) {
            .range_content {
              width: 100%;
              padding: 0;
              text-align: center;
            }
            .range_content h1 {
              font-size: 23px;
            }
          }
          @media (max-width: ${breakPoints.mobileL}) {
            .columns_button {
              min-width: 145px;
              font-size: 13px;
              padding: 8px;
            }
            .columns_button {
              font-size: 13px;
            }
            .range_information {
              width: 50% !important;
              height: 300px;
            }
            .range_image {
              height: 300px;
            }
            .range_content h1 {
              font-size: 23px;
            }
            .arrow_right_mobile {
              display: flex;
              justify-content: center;
              width: 32px;
              height: 32px;
              border-radius: 3px;
              cursor: pointer;
              background: #950810;
              position: absolute;
              bottom: 20px;
              right: 30px;
            }
            .arrow_right_mobile img {
              width: 20px;
            }
            .ranges_columns_title p {
              padding: 0 30%;
            }
          }

          @media (max-width: 580px) {
            .columns_button {
              min-width: 170px;
              padding: 12px;
            }
          }
          @media (max-width: ${breakPoints.mobileExtraLarge}) {
            .columns_button {
              min-width: 145px;
              padding: 7px;
              font-size: 16px;
              line-height: 16px;
            }
          }

          @media (max-width: ${breakPoints.mobileM}) {
            .columns_button {
              min-width: 100px;
              font-size: 13px;
              line-height: 16px;
              padding: 5px;
            }
          }

          @media (max-width: ${breakPoints.mobileS}) {
            .columns_button {
              min-width: 90px;
              font-size: 13px;
              padding: 5px;
              line-height: 16px;
            }
            .link_button {
              bottom: 80px;
            }
            .range_content h1 {
              font-size: 18px;
            }
          }
        `}
      </style>
    </>
  );
};

export default RangesColumns;
