export const loadChatBot = () => {
  const script = document.createElement('script');
  script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
  script.async = true;

  script.onload = () => {
    setTimeout(() => {
      if (window.FrontChat) {
        window.FrontChat('init', {
          chatId: '8a72f257b6dd910d3480a3c083486bbc',
          useDefaultLauncher: true,
        });
      }
    }, 1500);
  };

  document.body.appendChild(script);
};

export const cleanupChatBot = () => {
  const chatBotContainer = document.getElementById('front-chat-iframe');
  if (chatBotContainer) chatBotContainer.remove();
  if (window.FrontChat) delete window.FrontChat;
};
