import React, { useState, useEffect } from 'react';
import { fGetPlural } from 'utils/functions';

function BannerCountdownTimer({
  date,
  handleCloseBanner,
  hideBannerTimerRunsOut,
}) {
  const [daysText, setDaysText] = useState(0);
  const [hoursText, setHoursText] = useState(0);
  const [minutesText, setMinutesText] = useState(0);
  const [secondsText, setSecondsText] = useState(0);
  const [timerRunsOut, setTimerRunsOut] = useState(true);

  useEffect(() => {
    const timerDateTime = new Date(date).getTime();

    const timeInterval = setInterval(() => {
      const now = new Date().getTime();
      const timeleft = timerDateTime - now;

      if (timeleft < 0) {
        clearInterval(timeInterval);

        if (hideBannerTimerRunsOut) {
          handleCloseBanner();
        }

        setTimerRunsOut(false);
        localStorage.setItem('timerEnded', 'true');
      } else {
        const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

        setDaysText(days);
        setHoursText(hours);
        setMinutesText(minutes);
        setSecondsText(seconds);
      }
    }, 1000);

    return () => clearInterval(timeInterval);
  }, [date, handleCloseBanner, hideBannerTimerRunsOut]);

  return (
    <div className="countdown-text">
      <span className="countdown-time">
        {timerRunsOut && ' | Ends in'}
        {daysText > 0 && (
          <span>
            {fGetPlural({
              number: daysText,
              plural: 'd',
              singular: 'd',
            })}
          </span>
        )}
        {hoursText > 0 && (
          <span>
            {fGetPlural({
              number: hoursText,
              plural: 'h',
              singular: 'h',
            })}
          </span>
        )}
        {minutesText > 0 && (
          <span>
            {fGetPlural({
              number: minutesText,
              plural: 'm',
              singular: 'm',
            })}
          </span>
        )}
        {secondsText > 0 && (
          <span>
            {fGetPlural({
              number: secondsText,
              plural: 's',
              singular: 's',
            })}
          </span>
        )}
      </span>
      <style>
        {`
        .countdown-time {
            display: flex;
            gap: 3px;
            margin-left: 5px;
        }
        `}
      </style>
    </div>
  );
}

export default BannerCountdownTimer;
