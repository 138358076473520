/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
import React, { memo, useContext, useEffect, useState } from 'react';
import styles from './styles';
import Link from 'next/link';
import { StorefrontContext } from 'providers/storefront';
import { useRouter } from 'next/router';

const ProductList = ({ thirdMenuItem, handleClickNav, parent }) => {
  const { setSelectedGamingCategory } = useContext(StorefrontContext);
  const [ctaImage, setCtaImage] = useState(null);
  const preparedMenuItems = thirdMenuItem.items.slice(0, 3);
  const router = useRouter();

  useEffect(() => {
    setCtaImage(thirdMenuItem.items[0].model_image);
  }, [thirdMenuItem]);
  const handleSelectCategory = (item) => {
    setSelectedGamingCategory(item.primary.topic_title);
  };

  return (
    <>
      <div className="third_menu_section">
        <div className="third_menu_section_title">
          {thirdMenuItem.primary.topic_title}
        </div>
        <div className="third_menu_subsection">
          <div className="third_menu_image">
            <img src={ctaImage?.url} alt={ctaImage?.alt} />
          </div>
          <div className="third_menu_items">
            {preparedMenuItems.map((model) => (
              <Link href={model.link}>
                <a href={model.link}>
                  <div
                    className="third_menu_titles"
                    onMouseOver={() => setCtaImage(model.model_image)}
                    onClick={() => handleClickNav()}
                  >
                    <div className="third_menu_title">{model.title}</div>
                    <div className="third_menu_subtitle">{model.subtitle}</div>
                  </div>
                </a>
              </Link>
            ))}
            {parent.link === '/workstations/workstations' &&
              (router.asPath !== '/workstations/workstations' ? (
                <Link href={parent.link}>
                  <div
                    className="see-more-link"
                    onClick={() => handleSelectCategory(thirdMenuItem)}
                  >
                    See more {'>'}
                  </div>
                </Link>
              ) : (
                <div
                  className="see-more-link"
                  onClick={() => handleSelectCategory(thirdMenuItem)}
                >
                  See more {'>'}
                </div>
              ))}
            {parent.link === '/gaming/desktops' &&
              (router.asPath !== '/gaming/desktops' ? (
                <Link href={parent.link}>
                  <div
                    className="see-more-link"
                    onClick={() => handleSelectCategory(thirdMenuItem)}
                  >
                    See more {'>'}
                  </div>
                </Link>
              ) : (
                <div
                  className="see-more-link"
                  onClick={() => handleSelectCategory(thirdMenuItem)}
                >
                  See more {'>'}
                </div>
              ))}
          </div>
        </div>
      </div>

      <style jsx>{styles}</style>
    </>
  );
};

export default memo(ProductList);
